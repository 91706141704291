.footer {
  background: #f2f1ff;
  padding: 150px 0 50px 0;
  // margin-top: 50px;
  position: relative;
  overflow: hidden;
}
.footer::before {
  content: "";
  width: 102%;
  height: 200px;
  background: #fff;
  position: absolute;
  top: -116px;
  right: 0;
  // transform: rotate(-5deg);
  z-index: 1;
}
.footer .logo {
  color: #2f2d52;
  font-weight: 900;
}
.footer h6 {
  margin-top: 27px;
  margin-bottom: 30px;
}
.footer p {
  font-size: 14px;
  color: #77838f;
  line-height: 2;
  margin-top: 10px;
}
.footer .nav-item {
  margin-bottom: 10px;
}
.footer .link {
  font-size: 14px;
  color: #77838f;
  margin-bottom: 25px;
  text-decoration: underline;
}

.footer .img-fluid {
  width: 200px;
}

@media (max-width: 991px) {
  .footer {
    text-align: center !important;
  }

  .p-cen {
    text-align: center !important;
  }

  .par-aca {
    width: 100% !important;
  }
}
