.home {
  background: #2f2d52;
  position: relative;
  padding: 0px 0;
  overflow: hidden;

  @media screen and (max-width: 991px) {
    padding: 0px 0px;
  }

  .text-box {
    color: #fff;
    z-index: 100000;
    overflow: hidden;

    h1 span {
      color: #ffc78b;
    }
  }

  &::before {
    content: "";
    width: 103%;
    // height: 200px;
    background: #fff;
    position: absolute;
    bottom: -116px;
    // transform: rotate(-5deg);
    z-index: 1;
  }
}

.video-overlay {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
