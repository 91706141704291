.cart-section {
   margin-top: 200px;
   margin-bottom: 100px;
   h4 {
      margin-bottom: 50px;
   }
   .course-list {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 30px;
      border-top: 1px solid #e5e5e5;
      padding-top: 30px;
      img {
         width: 100px;
         border-radius: 3px;
      }
      .price {
         color: #666;
         font-size: 18px;
      }
      .title {
         max-width: 300px;
      }
      .btn-black.delete {
         width: 45px;
         height: 45px;
         padding-top: 5px;
      }
      &:first-child {
         margin-top: 0;
         padding-top: 0;
         border-top: 0px solid #e5e5e5;
      }
   }
   .success-alert,
   .failure-alert {
      background: #e6f7e7;
      color: #77d77f;
      width: 100%;
      height: 50px;
      border-radius: 3px;
      padding: 14px;
      text-align: center;
      margin: auto;
      margin-bottom: 20px;
   }
   .failure-alert {
      background: #fce4e0;
      color: #ff4500;
   }
   .estimation-box {
      h4 {
         margin-bottom: 20px;
      }
      border: 1px solid #e5e5e5;
      padding: 20px;
      border-radius: 3px;
      .btn-black {
         width: 100%;
      }
      ul {
         padding-left: 0;
         li {
            list-style: none;
            font-weight: 600;
            margin-bottom: 15px;
            span {
               float: right;
               color: #666;
               font-weight: normal;
            }
         }
         .total {
            border-top: 1px solid #e5e5e5;
            padding-top: 15px;
         }
      }
   }
}
