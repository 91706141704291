.chl-section {
  padding: 100px 0px 50px 0px;

  .header-text {
    margin-bottom: 30px;

    h3 {
      font-size: 24px;
      margin-bottom: 10px;
    }

    p {
      font-size: 16px;
      color: #666;
    }
  }

  .partners-logos {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    .partner-logo {
      max-width: 100%;
      margin: 10px;
      margin-top: 50px;
      transition: transform 0.3s;

      &:hover {
        transform: scale(1.05);
      }
    }
  }

  .partner-logo {
    cursor: pointer;
    height: 300px;
    object-fit: cover;
    width: 100%;
    min-width: 100%;
  }
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background: #f9f9f9;
  padding: 30px;
  border-radius: 15px;
  width: 400px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  text-align: center;
  animation: fadeIn 0.3s ease-in-out;
  margin-top: 150px;
}

.popup-content h4 {
  margin-bottom: 20px;
  color: #333;
}

.popup-content ul {
  list-style-type: disc;
  padding: 0;
}

.popup-content li {
  margin-bottom: 10px;
}

.popup-content button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.popup-content button:hover {
  background-color: #0056b3;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
