.ad-slider {
  height: 140px;
  img {
    max-height: 400px;
    object-fit: cover;
  }
}

@media (max-width: 991px) {
  .ad-slider {
    height: 100% !important;
    margin-top: 90px;
  }
}
