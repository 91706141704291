.join-us {
  margin-top: 200px;

  p {
    line-height: 2;
    margin-top: 30px;
  }
  li {
    margin: 10px;
  }

  h3 {
    margin-bottom: 30px;
    font-size: 24px;
    color: #2f2d52;
  }

  .form-group {
    margin-bottom: 30px;

    label {
      margin-bottom: 5px;
      font-size: 16px;
      color: #2f2d52;
    }

    input,
    textarea {
      border: none;
      color: #666;
      height: 55px;
      border-radius: 3px;
      padding: 20px;
      border: 1px solid #e5e5e5;

      &:focus {
        box-shadow: 0 0rem 0rem rgba(0, 0, 0, 0.15) !important;
        border: 1px solid #2f2d52;
      }
    }

    textarea {
      height: 165px;
    }
  }

  .btn-black {
    float: right;
    width: 50%;
    background-color: #2f2d52;
    color: white;
    border: none;
    padding: 10px;

    &:hover {
      background-color: #1f1e36;
    }
  }

  .error {
    color: red;
    font-size: 14px;
  }
}
