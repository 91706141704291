* {
  box-sizing: border-box !important;
}
html {
  margin: 0 !important;
  padding: 0 !important;
  box-sizing: border-box !important;
}

body {
  margin: 0 !important;
  padding: 0 !important;
  box-sizing: border-box !important;
}
*,
*:before,
*:after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  width: 100%;
  height: 100%;
}

body {
  font-family: "Cairo" !important;
  font-size: 16px;
}
h1 {
  font-size: 64px !important;
  font-weight: 700 !important;
}
h2 {
  color: #2f2d52 !important;
  font-size: 48px !important;
  font-weight: 700 !important;
}
h3 {
  color: #2f2d52 !important;
  font-size: 32px !important;
  font-weight: 600 !important;
}
h4 {
  color: #2f2d52 !important;
  font-size: 24px !important;
  font-weight: 600 !important;
}
h5 {
  color: #2f2d52 !important;
  font-size: 18px !important;
  font-weight: 600 !important;
}
.link {
  text-decoration: none;
  color: #121619;
  transition: 0.4s;
  &:hover {
    color: #fe9d3e;
  }
}
.error {
  font-size: 14px;
  color: #ff4500;
  text-transform: lowercase;
}
.header-text {
  text-transform: capitalize;
  margin-bottom: 50px;
  text-align: center;
  h3 {
    color: #2f2d51;
  }
  p {
    color: #77838f;
  }
}

.btn-black {
  background: #2f2d52;
  border: 1px solid #2f2d52;
  text-transform: capitalize;
  color: #fff;
  font-weight: 600;
  font-style: 16px;
  height: 50px;
  width: 100px;
  border-radius: 3px;
  transition: 0.4s;
  &:hover {
    background: transparent;
    color: #2f2d52;
  }
}
.home-pre-loader {
  position: relative;
  background-color: #fff;
  height: 100vh;
  z-index: 1031;
  .spinner-border {
    position: absolute;
    top: 50%;
  }
  .d-flex {
    height: 100%;
  }
}

.spinner-border {
  margin: auto;
  border: 0.25em solid #2f2d52 !important;
  border-right-color: transparent !important;
}
.loader {
  position: absolute;
  top: calc(50% - 4em);
  left: calc(50% - 4em);
  width: 6em;
  height: 6em;
  border: 1.1em solid rgba(0, 0, 0, 0.2);
  border-left: 1.1em solid #000000;
  border-radius: 50%;
  animation: load8 1.1s infinite linear;
  transition: opacity 0.3s;
}

.loader--hide {
  opacity: 0;
}

@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* media queries */
@media (max-width: 991px) {
  .navbar .navbar-nav .nav-item {
    text-align: center;
    margin: 15px 0;
  }
  .home .text-box h1 {
    font-size: 45px;
    font-weight: 700;
  }
  .home .text-box h1 span {
    font-size: 50px;
    font-weight: 700;
  }

  .navbar-collapse {
    background: #2f2d52;
    position: absolute;
    top: 90px;
    left: 0;
    right: 0;
  }
}
