.get-app-section {
  margin-top: 100px;
  .app-box {
    img {
      margin: 0 15px 15px 15px;
    }
  }
}

.contact-box {
  text-align: center;
  cursor: pointer;
  p {
    font-size: 14px;
    color: #77838f;
    margin-bottom: 0;
  }
  .icon {
    padding: 25px;
    background: #80808038;
    width: 100px;
    height: 100px;
    margin: auto;
    display: flex;
    justify-content: center;
    border-radius: 100px;
    margin: 50px auto;
    position: relative;
    img {
      width: 50px;
      height: 50px;
    }
    &::before {
      content: "";
      background: transparent;
      border: 1px solid #f5debc;
      width: 150%;
      height: 150%;
      position: absolute;
      top: -25px;
      left: -25px;
      border-radius: 100px;
    }
  }
}
