.category-page {
  margin-top: 50px;
  padding: 20px;
  width: 100%;

  .category-title {
    font-size: 2rem !important;
    color: #333;
    margin-bottom: 10px;
  }

  .category-description {
    font-size: 1.2rem;
    color: #666;
    line-height: 1.5;
    margin-bottom: 20px;
  }

  h2 {
    font-size: 1.5rem !important;
    color: #444;
    margin-top: 100px !important;
    text-align: start;
  }

  .courses-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
  }

  .course-card {
    flex: 1 1 calc(33.333% - 20px);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 15px;
    background-color: #fff;
    transition: transform 0.2s;
    text-decoration: none;

    &:hover {
      transform: scale(1.02);
    }

    h3 {
      font-size: 1rem !important;
      color: #333;
    }

    img {
      max-width: 100%;
      height: auto;
      border-radius: 4px;
      margin-top: 10px;
    }

    p {
      font-size: 0.9rem;
      color: #555;
      line-height: 2;
      margin-top: 10px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .loading {
    text-align: center;
    font-size: 1.5rem;
    color: #007bff;
  }

  .error {
    color: red;
    font-weight: bold;
    text-align: center;
  }

  .no-courses {
    text-align: center;
    color: #888;
    font-size: 1.2rem;
  }

  .btn-category-page {
    background: #2f2d52;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 40px;
  }

  @media (max-width: 991px) {
    .courses-container {
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    }

    .course-card {
      max-width: 100% !important;
    }
  }
}

.img-category-page {
  height: 200px;
  width: 100%;
  margin-top: 130px;
  object-fit: cover;
}
