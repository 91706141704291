.course-page {
  margin-top: 150px;

  .course-header {
    margin-bottom: 40px;

    .course-title {
      font-size: 2.5rem;
      color: #007bff;
    }

    .course-image {
      width: 100%;
      border-radius: 10px;
      margin-bottom: 20px;
    }

    .course-description {
      font-size: 1.25rem;
      margin-bottom: 20px;
    }
  }

  .lesson-card {
    transition: transform 0.2s;

    &:hover {
      transform: scale(1.05);
    }

    .card-title {
      font-size: 1.5rem;
      color: #343a40;
    }

    .card-text {
      font-size: 1rem;
      color: #6c757d;
    }
  }

  .link {
    text-decoration: none;
    color: white;
  }
}

.btn-close {
  position: absolute;
  left: 20px;
}
