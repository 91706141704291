.remote-video div {
  width: unset !important;
  height: 100% !important;
  position: unset !important;
  overflow: unset !important;
  background-color: black !important;
}

.remote-video video {
  border-radius: 30px;
}
.btn-live:hover {
  transform: translateY(1.2);
}

.remote-video #agora-video-player-track-video-3698973481-client-ec275_21719 {
  border-radius: 30px !important;
}

.btn-mic {
  background-color: #2f2d52;
  border-radius: 30px;
  color: white;
  border: none;
  padding: 15px;
  transition: 0.3s;
}
.btn-mic:hover {
  background-color: transparent;
  color: #2f2d52;
  border: 1px solid #2f2d52;
}

.btn-send-message {
  background-color: #2f2d52;
  border-radius: 30px;
  color: white;
  width: 10%;
  height: 40px;
  transition: 0.4s;

  &:hover {
    background-color: transparent;
    color: #2f2d52;
  }
}

.input-send-message {
  width: 100%;
  padding: 10px;
  border: 2px solid #2f2d52;
  border-radius: 5px;
  font-size: 16px;
  transition: border-color 0.3s;

  &:focus {
    border-color: darken(#2f2d52, 50%);
    outline: none;
  }
}

.scrollable-div {
  width: 80%;
  margin-top: 20px;
  max-height: 300px;
  overflow-y: scroll;
}

.scrollable-div::-webkit-scrollbar {
  width: 8px;
}

.scrollable-div::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.scrollable-div::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.scrollable-div::-webkit-scrollbar-thumb:hover {
  background: #555;
}
