.row {
  margin: 0;
  padding: 20px;
}

.pdf-box {
  background: #fff;
  border-radius: 15px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s, box-shadow 0.3s;
  cursor: pointer;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2);
  }

  .img-fluid {
    width: 100%;
    height: 250px;
    object-fit: cover;
    border-bottom: 5px solid #2f2d52;
    transition: transform 0.3s;

    // &:hover {
    //   transform: scale(1.05);
    // }
  }

  .info {
    padding: 15px;

    .category {
      font-size: 14px;
      color: #6c757d;
      margin-bottom: 5px;
    }

    .title {
      font-size: 18px;
      font-weight: bold;
      color: #333;
      margin-bottom: 10px;
    }

    .d-flex {
      justify-content: space-between;
      align-items: center;

      .icon {
        color: #2f2d52;
        margin-right: 5px;
      }
    }
  }
}
