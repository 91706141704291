.consulting-and-studies {
  padding: 20px;
  border-radius: 8px;
  margin-top: 200px;

  .content {
    display: flex;
    align-items: flex-start;
    flex-direction: row-reverse;

    .text-content {
      flex: 1;
      padding: 0 50px;
    }

    .image-content {
      flex: 0 0 50%;
      img {
        max-width: 100%;
        border-radius: 8px;
      }
    }
  }

  h3 {
    color: #333;
    font-size: 32px;
    margin-bottom: 15px;
  }

  p {
    color: #77838f;
    line-height: 1.6;
    margin-bottom: 20px;
  }

  .bullet-points {
    list-style-type: disc;
    padding-left: 20px;

    li {
      color: #77838f;
      margin-bottom: 10px;
      font-size: 1rem;
    }
  }
}

@media (max-width: 991px) {
  .consulting-and-studies .content {
    flex-wrap: wrap !important;
  }

  .image-content {
    flex: 0 0 100% !important;
  }
}
