.video-library {
  padding: 20px;
  text-align: center;
  margin-top: 150px;

  h1 {
    margin-bottom: 30px;
    color: #333;
    font-weight: bold;
  }

  .btn-buy {
    background: #2f2d52;
    color: white;
    padding: 10px;
    border-radius: 40px;
    border: none;
    padding-right: 15px;
    padding-left: 15px;
  }

  .video-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    .video-item {
      background-color: #fff;
      border: 1px solid #ddd;
      border-radius: 8px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
      width: 300px;
      padding: 10px;

      .video-thumbnail {
        width: 100%;
        border-radius: 4px;
      }

      h2 {
        font-size: 1.2em;
        color: #555;
        margin: 10px 0;
      }

      p {
        font-size: 0.9em;
        color: #777;
      }

      video {
        width: 100%;
        border-radius: 4px;
      }
    }
  }
}
