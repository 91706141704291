$courses-bg: #ffffff;
$courses-border: #dcdcdc;
$courses-hover-bg: #e6f7ff;
$primary-color: #007bff;
$text-color: #333;
$secondary-text-color: #666;

.program-courses {
  margin-top: 150px;
  text-align: center;

  h2 {
    color: $primary-color;
    font-size: 2em !important;
    margin-bottom: 20px;
  }

  .courses-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 20px 0;
    padding: 0 15px;

    .course-item {
      background-color: $courses-bg;
      border: 1px solid $courses-border;
      border-radius: 10px;
      padding: 20px;
      margin: 15px;
      flex: 1 0 21%;
      max-width: 33.3333333%;
      transition: transform 0.3s, box-shadow 0.3s;
      text-decoration: none;

      &:hover {
        transform: translateY(-5px);
        box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
      }

      img {
        max-width: 100%;
        height: auto;
        border-radius: 8px;
        margin-bottom: 10px;
      }

      h4 {
        font-size: 1.3em;
        margin: 10px 0;
        color: $primary-color;
      }

      p {
        font-size: 0.9em;
        color: $secondary-text-color;
        line-height: 1.5;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  @media (max-width: 991px) {
    .course-item {
      max-width: 100% !important;
    }
  }
}
