.navbar {
  background: #2f2d52;
  padding: 15px 0;

  .logo {
    height: 70px;
    object-fit: cover;
  }

  .navbar-nav {
    margin-left: auto;
    margin-right: auto;

    .nav-item .link {
      color: #fff;
      text-decoration: none;
      margin: 0 15px;
      font-size: 13px;
      cursor: pointer;
    }
  }

  .user-cart {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      margin: 0 15px;

      img {
        width: 24px;
        cursor: pointer;
      }
    }
  }

  .dropdown-menu {
    position: absolute;
    background-color: white;
    border: 1px solid #ddd;
    z-index: 1000;

    .dropdown-item {
      text-align: right;
      color: #333;

      &:hover {
        background-color: #f8f9fa;
      }

      &.dropdown-left {
        text-align: left !important;
      }

      &.dropdown-right {
        text-align: right !important;
      }
    }
  }

  .search-box {
    position: relative;

    input {
      padding: 10px;
      border-radius: 25px;
      border: none;
      outline: none;
      width: 0;
      transition: width 0.5s ease;

      &:focus {
        width: 200px;
      }
    }

    .btn-search {
      background: none;
      border: none;
      cursor: pointer;
    }

    .search-results {
      position: absolute;
      background: white;
      border: 1px solid #ddd;
      z-index: 1000;

      li {
        padding: 10px;
        cursor: pointer;

        &:hover {
          background: #f8f9fa;
        }
      }
    }
  }
}

.whatsapp-icon {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  cursor: pointer;
  width: 60px;
  height: 60px;

  img {
    width: 100%;
    height: 100%;
  }
}
