/* CONTACT */
.contact {
   margin-top: 200px;
   h3 {
      margin-bottom: 30px;
   }
   .form-group {
      margin-bottom: 30px;
      label {
         margin-bottom: 5px;
         font-size: 16px;
         color: #2f2d52;
      }
      input,
      select {
         border: none;
         color: #666;
         height: 55px;
         border-radius: 3px;
         padding: 20px;
         border: 1px solid #e5e5e5;
         &:focus {
            box-shadow: 0 0rem 0rem rgba(0, 0, 0, 0.15) !important;
            border: 1px solid #2f2d52;
         }
      }
      textarea {
         color: #333;
         height: 165px;
         border-radius: 3px;
         padding: 20px;
         border: 1px solid #e5e5e5;
         &:focus {
            box-shadow: 0 0rem 0rem rgba(0, 0, 0, 0.15) !important;
            border: 1px solid #2f2d52;
         }
      }
   }
   .btn-black {
      float: right;
      width: 50%;
   }
}
