/* COURSE */
.course-box {
  background: #fff;
  margin: 10px;
  border-radius: 10px;
  margin-bottom: 30px;
  padding: 10px;
  cursor: pointer;
  height: 100%;
  img {
    border-radius: 10px;
    margin-bottom: 15px;
    width: 100%;
    height: 300px;
    object-fit: cover;
  }
  .info {
    padding: 10px;
    .category {
      color: #77838f;
      margin-bottom: 10px;
    }
    .title {
      color: #2f2d51;
      margin-bottom: 10px;
    }
    .price {
      color: #444;
      margin-bottom: 0px;
      font-weight: 600;
    }
    .review {
      color: #77838f;
      font-size: 14px;
    }
    .fa-star {
      color: #ffc78c;
      font-size: 14px;
    }
    span,
    .icon {
      color: #77838f;
      font-size: 14px;
    }
  }
}
@media (max-width: 991px) {
  .course-box img {
    height: auto !important;
  }
}
