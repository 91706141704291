/* NOT FOUND */
.not-found {
   margin-top: 200px;
}
.not-found img {
   margin-bottom: 50px;
}
.not-found h3 {
   color: #2f2d51;
   font-weight: 600;
   font-size: 35px;
   margin-bottom: 30px;
}
.not-found p {
   color: #77838f;
}
