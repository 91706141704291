$primary-color: #2f2d52;
$secondary-color: #333;
$background-color: #f4f4f4;
$text-color: #555;

@mixin center-align {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.chl-h1 {
  font-size: 2.5rem !important;
  color: $primary-color;
  margin-bottom: 20px;
  text-align: center;
}

.img {
  width: 100%;
  height: 100px;
  height: auto;
  border-radius: 10px;
  margin-bottom: 20px;
  margin: auto;
  display: block;
}

.chl-text {
  @include center-align;
  margin-top: 50px;
  padding: 20px;

  text-align: center;
  font-size: 1.2rem;
  line-height: 1.6;
  transition: transform 0.2s;
}
