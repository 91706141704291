.manage-menus {
   margin-top: 25px;
   overflow: auto;
   .menu-table {
      h4 {
         margin-top: 15px;
         margin-bottom: 30px;
         text-transform: uppercase;
      }
      .table {
         caption-side: bottom;
         border-collapse: collapse;
         border-spacing: 0px;
         border-radius: 3px;
         tbody {
            border-top: 0px;
         }
         thead {
            background: #f7f7f8;
            th {
               color: #121619;
               font-weight: 600;
               padding: 15px !important;
               border: 1px solid #e5e5e5;
               text-align: center;
            }
         }
         td {
            border: 1px solid #e5e5e5;
            color: #666;
            padding: 10px !important;
            text-align: center;
            align-items: center;
            p {
               height: 100px;
               display: flex;
               align-items: center;
               justify-content: center;
               margin-bottom: 0;
            }
            img {
               width: 100px;
               height: 100px;
            }
            .delete {
               height: 40px;
               width: 40px;
               border-radius: 3px;
               .fa-icon {
                  font-size: 12px;
               }
            }
         }
      }
   }
}
